/*-------------------------------------
Template Name: Onova  
Template URI: https://ryetheme.com/
Description: IT Solutions and Services Company HTML Template.
Author: webtend
Author URI: https://webtend.net/
Version: 1.0
---------------------------------------*/

/*--------------------------------------
CSS INDEX:-

1.Default css
2.preloader css
3.index_1.html css
4.index_2.html css
5.zinco_breadcrumb section css
6.404.html section css
7.About.html css
8.SEO_service.html css
9.PPC_service.html css
10.SEM_service.html css
11.portfolio_2_column.html css
12.team.html css
13.testimonial.html css
14.faq.html css
15.shop.html css
16.shop_2.html css
17.shop_details.html 
18.blog_grid.html css
19.blog_standard.html css 
21.blog_details.html css
22.contact.html css
23.comeingsoon.html css
24.index.html css

---------------------------------------*/

/*google fonts*/
@import url('https://fonts.googleapis.com/css?family=Karla:400,700&display=swap');
/*google fonts*/

/*
	1. Satrt Default css 
*/
@font-face {
    font-family: 'Gilroy-Bold.ttf';
    src: url(../fonts/Gilroy-Bold.ttf);
}

* {
    margin: 0;
    padding: 0;
    outline: none;
}

a,
a:hover,
a:focus {
    color: #170e2a;
    text-decoration: none;
    outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Gilroy-Bold.ttf';
    text-transform: capitalize;
    margin: 0;
    color: #0d2335;
    font-weight: bold;
}

h1 {
    font-size: 72px;
}

h2 {
    font-size: 50px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

p {
    margin: 0;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

input,
input:hover,
input:focus {
    outline: none;
}

textarea,
textarea:hover,
textarea:focus {
    outline: none;
}

button,
button:hover,
button:focus {
    cursor: pointer;
    outline: none;
    border: none;
}

body {
    font-family: 'Karla', sans-serif;
    font-size: 16px;
    color: #758ca0;
    line-height: 26px;
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
}




.theme_title {
    margin-bottom: 66px;
}

span.theme_span {
    font-size: 16px;
    color: #0574ff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.theme_title span.theme_span {
    margin-bottom: 15px;
}

.theme_title h2 {
    font-size: 60px;
}

.onova_btn {
    position: relative;
    display: inline-block;
    border: none;
    border-radius: 6px;
    padding: 15px 44px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    background: #0574ff;
    text-transform: uppercase;
    transition: all .5s;
}

.onova_btn:hover,
.onova_btn:focus {
    background: #00247e;
    color: #fff;
}

.onova_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(13, 35, 53);
    opacity: 0.902;
}

.bg_image {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.bg_image_service {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* Media query for mobile devices */
@media only screen and (max-width: 530px) {
    .bg_image {
        background-position: calc(100% + 320px) center;
        /* Adjust the pixel value as needed */
        /* Show only the right side of the image in the center */
        background-size: cover;
        /* Maintain aspect ratio */
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

/*
	End Default css 
*/

/*
	2. Start Preloader css 
*/
.preloader_area {
    position: fixed;
    background: #fff;
    z-index: 11000;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.lodar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lodar ul {
    display: flex;
}

.lodar ul li {
    display: inline-block;
    font-size: 60px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: 10px;
    animation: animate 1.4s linear infinite;
}

@keyframes animate {
    0% {
        color: #ef3b58;
    }

    50% {
        color: #ef3b58;
    }

    100% {
        color: #ef3b58;
    }
}

.lodar ul li:nth-child(1) {
    animation-delay: .7s;
}

.lodar ul li:nth-child(2) {
    animation-delay: 1s;
}

.lodar ul li:nth-child(3) {
    animation-delay: 1.5s;
}

.lodar ul li:nth-child(4) {
    animation-delay: 2s;
}

.lodar ul li:nth-child(5) {
    animation-delay: 2.5s;
}

/*
	End Preloader css 
*/

/*==================================================
		3. Onova==Index_1.html
====================================================*/
/*
	Start onova_header area css
*/
header.onova_header.onova_header_1 {
    border-bottom: 1px solid #e7f3ff;
}

.onova_header_1 .header_container_1 {
    max-width: 1730px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
}

.onova_header_1 .header_menu_right .menu_avatar_group {
    display: inline-flex;
}

.onova_header_1 .header_menu_right .menu_box_group {
    margin-left: 60px;
    display: inline-flex;
}

.onova_header_1 .header_menu_right .menu_box_group {
    margin-left: 60px;
}

.onova_header_1 .header_menu_right .menu_avatar_group a.btn_phone {
    margin-left: 20px;
    color: #0574ff;
    font-size: 16px;
}

.onova_header_1 .header_menu_right .menu_avatar_group a.btn_phone i {
    margin-right: 5px;
}

.onova_header_1 .header_menu_right .menu_box_group .menu_box_list a.menu_box {
    display: block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid rgb(220, 234, 247);
    color: #758ca0;
    font-size: 16px;
    margin-left: 20px;
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #fff;
    animation: navigation .4s;
}



@-webkit-keyframes navigation {
    0% {
        top: -100px;
    }

    100% {
        top: 0;
    }
}

@keyframes navigation {
    0% {
        top: -100px;
    }

    100% {
        top: 0;
    }
}


@media screen and (min-width: 1200px) {
    /*.onova_header_1 .container{
		width: 100%;
	}
	.onova_header_1 .onova_main_menu_1{
		position: relative;
		flex-grow: 1;
		justify-content: center;
	}
	.onova_header_1 .header_menu_right {
	    display: flex;
	    flex-wrap: wrap;
	    align-items: center;
	}*/
}

/*
	onova menu
*/
nav.main_menu {
    text-align: center;
}

.main_menu ul li {
    position: relative;
    display: inline-block;
    padding: 0 15px;
}

.main_menu ul li>a {
    display: block;
    color: #758ca0;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    padding: 45px 0;
    transition: all .5s;
}

.main_menu ul li a span {
    margin-left: 5px;
}

.main_menu ul li:hover>a {
    color: rgb(5, 116, 255);
    border-color: rgb(5, 116, 255);
}

.main_menu ul li ul.sub_menu {
    position: absolute;
    text-align: left;
    width: 250px;
    max-height: 0px;
    padding: 0px;
    top: 100%;
    left: 0px;
    background: #fff;
    opacity: 0;
    z-index: 9999;
    border-top: 4px solid rgb(5, 116, 255);
    visibility: hidden;
    -webkit-box-shadow: 0px 0px 54px -31px rgba(158, 158, 158, 1);
    -moz-box-shadow: 0px 0px 54px -31px rgba(158, 158, 158, 1);
    box-shadow: 0px 0px 54px -31px rgba(158, 158, 158, 1);
    transition: all .3s;
}

.main_menu ul li:hover>ul.sub_menu {
    visibility: visible;
    top: 100%;
    max-height: 800px;
    padding: 15px;
    opacity: 1;
    transition: all .5s;
}

.main_menu ul li ul.sub_menu li {
    display: block;
    margin: 0;
}

.main_menu ul li ul.sub_menu li:last-child {
    border-bottom: none;
}

.main_menu ul li ul.sub_menu li a {
    font-size: 14px;
    color: #092f5d;
    padding: 10px 0 !important;
}

.main_menu ul li ul.sub_menu li:hover>a {
    color: #ef3b58;
    border-top: 0;
}

.main_menu ul li ul.sub_menu li ul.sub_menu {
    left: 100%;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.main_menu ul li ul.sub_menu li:hover ul.sub_menu {
    visibility: visible;
    opacity: 1;
}

/*
	header_menu
*/
/* Mobile menu */
.mobile_menu {
    display: none;
    padding: 10px 0;
}

.custom-logo-width {
    width: 120px;
}

.custom-logo-width-mobile {
    width: 90px;
}

.mobile_menu_logo a.logo {
    font-size: 18px;
    color: #fff;
}

.mobile_menu_icon .menu_icon {
    font-size: 18px;
    color: #000;
    float: right;
}

.mobile_menu2 .mobile_menu_icon .menu_icon {
    font-size: 18px;
    color: #fff;
    float: right;
}

.mobile_menu_icon a {
    margin-left: 20px;
    cursor: pointer;
}

.mobile_menu3 .mobile_menu_icon .menu_icon {
    color: #fff;
}

.mobile_menu3 .mobile_menu_icon a {
    color: #fff;
    margin-right: 20px;
}

.sidenav_menu {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: -100%;
    padding: 50px 20px;
    background: rgb(5, 116, 255);
    transition: all .5s ease-in-out;
}

.sidenav_menu.active {
    left: 0;
    z-index: 9;
}

.times_icon {
    position: absolute;
    right: 0;
    top: 0;
}

.times_icon i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #fff;
    color: #000a2d;
    font-size: 18px;
}

.sidebar-menu>li>a {
    font-size: 16px;
    color: #fff;
    text-transform: capitalize;
    padding: 10px 20px;
    font-weight: 500;
}

.sidebar-menu .sidebar-submenu>li>a {
    padding: 5px 25px;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
}

.sidebar-menu .sidebar-submenu>li:hover>a {
    color: #fff;
}

.sidebar-menu .sidebar-submenu {
    padding-left: 0px;
    background: rgb(5, 116, 255);
}

.sidebar-menu>li:hover>a,
.sidebar-menu>li.active>a {
    color: #fff;
    background: #ffffff;
}

.brand_logo a {
    font-weight: bold;
    color: #fff;
    font-size: 30px;
}

.brand_logo {
    margin-bottom: 20px;
}

.sidebar_search {
    margin-bottom: 40px;
}

.sidebar_search input {
    background: #fff;
    border: none;
    width: 100%;
    height: 60px;
    font-size: 16px;
    color: #000a2d;
    outline: none;
    padding: 0 20px;
}

/* Mobile menu */
/*
	End onova_header area css
*/

/*
	Start onova_main section css
*/
.onova_main_1 {
    padding: 110px 0;
    overflow: hidden;
}

.onova_main_1_img {
    position: absolute;
    right: 0;
    z-index: -1;
    top: 100%;
}

.main_container {
    margin: 0 auto;
}

.main_container_1 {
    max-width: 1470px;
}

.main_row {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;
}

.main_container .main_row .main_left {
    overflow: hidden;
}

.main_container .main_row .main_left,
.main_container .main_row .main_right {
    width: 50%;
}

.onova_content h1 {
    margin-bottom: 15px;
}

.onova_content mark {
    display: block;
    padding: 30px;
    border-radius: 6px;
    background: rgb(5, 116, 255);
    margin-bottom: 60px;
}

.onova_content mark h2 {
    text-transform: uppercase;
    color: #fff;
    font-size: 56px;
}

.main_container_1 .main_right .main_img {
    float: right;
    padding-left: 100px;
}

.onova_head_box_1 {
    display: flex;
}

.onova_head_box_1 .head_box_1 {
    display: inline-flex;
    width: 50%;
}

.onova_head_box_1 .head_box_1 .box_icon {
    margin-right: 40px;
}

.onova_head_box_1 .head_box_1 .box_icon i {
    color: #0574ff;
    font-size: 30px;
    transition: all .5s;
}

.onova_head_box_1 .head_box_1 .box_info h3 {
    font-size: 30px;
    margin-bottom: 15px;
}

.onova_head_box_1 .head_box_1:hover .box_icon i {
    transform: rotate(-360deg);
}

@media screen and (min-width: 1200px) {
    .main_container {
        width: 100%;
    }

    .onova_content mark h2 {
        font-size: 38px;
    }
}

/*
	End onova_main section css
*/

/*
	Start onova_about section css
*/
.about_container {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.about_container .about_left,
.about_container .about_right {
    width: 50%;
}

.about_container .about_left img {
    width: 100%;
}

.about_container .about_right,
.about_container .about_left {
    padding-left: 100px;
    padding-right: 100px;
}

.about_right_content span.dot {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgb(5, 116, 255);
    border: 4px solid rgb(255, 255, 255);
    box-shadow: 0px 10px 20px 0px rgba(5, 116, 255, 0.4);
    margin-bottom: ;
}

.about_right_content span.about_span {
    display: block;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    color: #0574ff;
    margin-top: 30px;
    margin-bottom: 20px;
}

.about_right_content h2 {
    margin-bottom: 19px;
}

.about_right_content h3 {
    font-size: 26px;
    line-height: 36px;
    color: #758ca0;
    margin-bottom: 30px;
}

.about_right_content p {
    margin-bottom: 35px;

}

.about_right_content .about_content_block {
    padding-left: 40px;
    border-left: 2px solid #ceefff;
}

.about_right_content .onova_button {
    margin-top: 40px;
}

/*
	End onova_about section css
*/

/*
	Start onova_video_1 section css
*/
.onova_video_1 {
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    padding-top: 110px;
    padding-bottom: 15px;
}

.overlay_title span.theme_span {
    color: #fff;
}

.overlay_title h2 {
    color: #fff;
}

.video_button_1 a.play_btn {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: rgb(5, 116, 255);
    color: #fff;
    font-size: 20px;
    z-index: 1;
}

.video_button_1 a.play_btn:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border: 2px solid #495a69;
    background: rgba(5, 116, 255, 0);
    top: 50%;
    left: 50%;
    z-index: -1;
    overflow: hidden;
    transform: translate(-50%, -50%);
    animation: pulse-border 1500ms ease-out infinite;
}

.video_button_1 a.play_btn:before {
    position: absolute;
    content: '';
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 2px solid #495a69;
    background: rgba(5, 116, 255, 0);
    top: 50%;
    left: 50%;
    z-index: -1;
    overflow: hidden;
    transform: translate(-50%, -50%);
    animation: pulse-border 1500ms ease-out infinite;
}

.video_button_1 {
    margin-bottom: 80px;
}

.video_button a.play_btn {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: rgb(5, 116, 255);
    color: #fff;
    font-size: 20px;
    z-index: 1;

}

.video_button a.play_btn:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border: 2px solid #495a69;
    background: rgba(5, 116, 255, 0);
    top: 50%;
    left: 50%;
    z-index: -1;
    overflow: hidden;
    transform: translate(-50%, -50%);
    animation: pulse-border 1500ms ease-out infinite;
}

.video_button a.play_btn:before {
    position: absolute;
    content: '';
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 2px solid #495a69;
    background: rgba(5, 116, 255, 0);
    top: 50%;
    left: 50%;
    z-index: -1;
    overflow: hidden;
    transform: translate(-50%, -50%);
    animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) scale(1);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) scale(1.3);
        opacity: 1;
    }
}

.video_image_area {
    margin-bottom: -170px;
}

/*
	End onova_video_1 section css
*/

/*
	Start onova_service_1 section css
*/
.onova_service_1 {
    position: relative;
    padding-top: 267px;
    padding-bottom: 120px;
}

.service_box_1 {
    background: rgb(255, 255, 255);
    box-shadow: 0px 8px 16px 0px rgba(207, 228, 255, 0.2);
    transition: all 5s;
}

.service_box_1:hover {
    box-shadow: 0px 16px 32px 0px rgba(207, 228, 255, 0.8);
}

.service_box_1 .service_img {
    position: relative;
}

.service_box_1:hover .service_icon span {
    top: -50%;
}

.service_box_1:hover .service_icon i.icon_btn {
    top: 50%;
}

.service_box_1 .service_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    overflow: hidden;
    background: rgb(255, 255, 255);
    transition: all .5s;
}

.service_box_1 .service_icon span {
    font-size: 40px;
    color: #0061da;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .5s;
}

.service_box_1 .service_icon i.icon_btn {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: #0061da;
    transition: all .5s;
}

.service_box_1 .service_info {
    text-align: center;
    padding: 40px 0;
}

.service_box_1 .service_info h4 {
    margin-bottom: 22px;
}

.service_box_1 .service_info p {
    margin-bottom: 30px;
}

.service_box_1 .service_info h6 {
    color: #0574ff;
}

/*
	End onova_service_1 section css
*/



/*
	Start why_choose_1 section css
*/
.why_choose_1 {
    position: relative;
    padding-top: 110px;
    padding-bottom: 90px;
}

.why_choose_1 .theme_title {
    margin-bottom: 80px;
}

.mb_30 {
    margin-bottom: 30px;
}

.choose_box_1 {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.choose_box_1 .choose_info {
    border: 2px solid rgb(66, 85, 99);
    padding: 50px;
    backface-visibility: hidden;
    box-sizing: border-box;
}

.choose_box_1 .choose_info span {
    display: block;
    color: #0574ff;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 15px;
}

.choose_box_1 .choose_info h3 {
    /* color: #fff; */
    margin-bottom: 15px;
    font-size: 34px;
}

.choose_box_1 .choose_info p {
    margin-bottom: 35px;
}

.choose_box_1 .choose_info a {
    color: #657a8a;
    margin-top: 30px;
}

.choose_box_1 .choose_box_bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -o-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    opacity: 0;
    transition: all .5s ease;
}

.single_service_card {
    height: 450px;
    /* Adjust the height as needed */
    overflow: hidden;
    /* Hide overflowing content */
}

.single_benefit_card {
    height: 500px;
    overflow: hidden;
}

.choose_box_1:hover .choose_box_bg {
    opacity: 1;
    transform: rotateY(0deg);
}

.choose_box_1 .choose_box_bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 126, 251, 0.9);
}

.choose_box_1 a.choose_box_bg span.choose_icon_box i {
    font-size: 70px;
    color: #fff;
}

.choose_box_1 .choose_box_bg .choose_icon_box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

/* .choose_box_1:hover .choose_info {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    opacity: 0;
} */

/*
	End why_choose_1 section css
*/

/*
	Start onova_portfolio_1 section css
*/
.case_single {
    position: relative;
    overflow: hidden;
    margin-left: 25px;
    margin-right: 25px;
}

.case_single:hover .case_overlay {
    opacity: .9;
    visibility: visible;
}

.case_single:hover .case_content {
    bottom: 60px;
}

.case_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: visible;
    background: -moz-linear-gradient(90deg, rgba(13, 35, 53, 0.6) 0%, rgba(13, 35, 53, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(13, 35, 53, 0.6) 0%, rgba(13, 35, 53, 0) 100%);
    background: -ms-linear-gradient(90deg, rgba(13, 35, 53, 0.6) 0%, rgba(13, 35, 53, 0) 100%);
    transition: all .5s;
}

.case_content {
    position: absolute;
    bottom: -100%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    transition: all .5s;
}

.case_tag {
    text-align: center;
}

.case_tag a.tag {
    font-size: 16px;
    color: #fff000;
    font-weight: 700;
    text-transform: uppercase;
}

.case_content h3 {
    color: #fff;
}

.case_study_slide .slick-prev {
    z-index: 1;
    top: -54%;
    left: 78%;
}

.case_study_slide .slick-next {
    top: -54%;
    right: 12%;
}

.case_study_slide .slick-prev:hover:before,
.case_study_slide .slick-next:hover:before {
    background: #0574ff;
    color: #fff;
}

.case_study_slide .slick-prev:before {
    content: '\f060';
}

.case_study_slide .slick-next:before {
    content: '\f061';
}

.case_study_slide .slick-prev:before,
.case_study_slide .slick-next:before {
    display: block;
    font-family: 'Font Awesome 5 Pro';
    width: 74px;
    height: 74px;
    line-height: 74px;
    text-align: center;
    border: 1px solid rgb(223, 240, 254);
    background: #fff;
    border-radius: 6px;
    font-size: 20px;
    color: #c0d3e2;
    transition: all .5s;
}

.onova_portfolio_1 {
    padding-top: 110px;
    padding-bottom: 120px;
}

.onova_case_main_section {
    padding-top: 113px;
    padding-bottom: 120px;
}

.onova_blog.onova_blog_3 {
    padding-bottom: 90px;
    padding-top: 120px;
}

.onova_blog.onova_blog_4 {
    padding-bottom: 90px;
    padding-top: 120px;
}

.onova_blog_list {
    padding-top: 120px;
    padding-bottom: 120px;
}

/*
	End onova_portfolio_1 section css
*/

/*
	Start onova_contact_1 section css
*/
.onova_contact_1 {
    position: relative;
    padding-top: 110px;
    padding-bottom: 120px;
    overflow: hidden;
}

.onova_contact_1 .theme_title {
    margin-bottom: 56px;
}

.overlay_img {
    position: absolute;
    top: 0;
    right: 150px;
}

.light_bg {
    background: #f9fdff;
}

.form_group {
    position: relative;
}

.form_group label {
    display: block;
    color: #0d2335;
    font-size: 16px;
    margin-bottom: 10px;
}

.form_control {
    position: relative;
    width: 100%;
    height: 70px;
    padding: 30px 40px;
    margin-bottom: 30px;
    font-size: 14px;
    color: #758ca0;
    border: 1px solid rgb(207, 230, 241);
}

.form_group i {
    position: absolute;
    right: 25px;
    top: 65px;
}

textarea.form_control {
    height: 180px;
}

/*
	End onova_contact_1 section css
*/


/*
	Start onova_team_1 section css
*/
.blue_bg {
    background: #0574ff;
}

.onova_team {
    padding-top: 110px;
    padding-bottom: 120px;
}

.onova_team.onova_team_3 {
    padding-bottom: 90px;
}

.team_title span.theme_span,
.team_title h2 {
    color: #fff;
}

.team_box_1 {
    margin-bottom: 80px;
    transition: all .5s;
}

.team_box_1:hover .team_info {
    border-color: #65a9ff;
}

.team_info {
    padding: 40px;
    border: 1px solid #1d81ff;
    border-top: none;
}

.team_info h3 {
    font-size: 32px;
    color: #fff;
    line-height: 70px;
}

.team_info p {
    font-size: 14px;
    color: #add1ff;
    text-transform: uppercase;
}

.team_join_button .onova_btn {
    background: transparent;
    border: 1px solid #69acff;
    margin-left: 25px;
    margin-right: 25px;
}

.team_join_button .onova_btn:hover,
.team_join_button .onova_btn:focus {
    background: #0061da;
    border-color: #0061da;
}

/*
	End onova_team_1 section css
*/

/*
	Start call_to_action section css
*/
.call_to_action_1 {
    padding: 110px 0 115px;
}

.call_title {
    margin-bottom: 50px;
}

.call_to_action_1 .call_title {
    margin-bottom: 62px;
}

.call_title h2 {
    color: #fff;
    font-size: 55px;
}

.call_action_img {
    position: relative;
}

.video_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video_popup .play_btn {
    display: block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: 0px 3px 7px 0px rgba(159, 195, 229, 0.4);
    font-size: 16px;
    color: #0574ff;
}

.call_button {
    float: right;
    margin-bottom: 80px;
}

/*
	End call_to_action section css
*/

/*
	Start onova_blog section css
*/
.blog_box_1 {
    background: #fff;
    transition: all .5s;
}

.onova_blog {
    padding-top: 110px;
    padding-bottom: 120px;
}

.blog_box_1:hover {
    box-shadow: 0px 10px 20px 0px rgba(203, 230, 255, 0.4);
}

.blog_box_1:hover .blog_info {
    border-color: #fff;
}

.blog_box_1:hover .post_meta {
    border-color: #fff;
}

.blog_box_1 .blog_info {
    padding: 32px 20px 25px 35px;
    border: 1px solid #d6dfea;
}

.post_tag {
    margin-bottom: 10px;
}

.post_tag a.tag_link {
    font-size: 16px;
    color: #0574ff;
    font-weight: 700;
    text-transform: uppercase;
}

.post_text h3 {
    font-size: 30px;
    margin-bottom: 6px;
    line-height: 30px;
}

.post_meta ul {
    display: flex;
    text-align: center;
    justify-content: space-around;
}

.blog_box_2 .post_meta ul {
    justify-content: space-between;
}

.post_meta {
    padding: 15px 0;
    border: 1px solid #d6dfea;
    border-top: none;
}

.post_meta ul li {
    display: inline-block;
}

.post_meta ul li i {
    margin-right: 7px;
}

.post_meta ul li a {
    text-transform: capitalize;
    color: #99abbb;
    font-size: 14px;
}

.pagination ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/*
	End onova_blog section css
*/

/*
	Start onova_footer_widget section
*/
.dark_blue {
    background: #091a29;
}

.call_to_action_3.dark_blue {
    padding-top: 110px;
    padding-bottom: 117px;
}

.footer_widget_1 {
    padding: 80px 0 80px;
}

.onova_footer_widget.footer_widget_1.footer_widget_3 {
    padding: 35px 0 80px;
}

.widget_top_area {
    padding-bottom: 80px;
    border-bottom: 1px solid #294157;
}

.widget_top_area .footer_social {
    float: right;
}

.footer_social ul li {
    display: inline-block;
    margin-left: 20px;
}

.footer_social ul li a {
    display: block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    background: rgb(18, 40, 60);
    text-align: center;
    font-size: 16px;
    color: #a1b8ce;
    transition: all .5s;
}

.footer_social ul li a:hover,
.footer_social ul li a:focus {
    background: rgb(5, 116, 255);
    color: #fff;
}

.widget_area {
    padding-top: 80px;
}

.footer_title {
    margin-bottom: 38px;
}

.footer_title h5 {
    color: #fff;
}

.footer_widget_list ul.widget_list li {
    line-height: 36px;
}

.footer_widget_list ul.widget_list li a {
    font-size: 14px;
    color: #8fa3b4;
}

.footer_contact_info p {
    line-height: 36px;
}

.footer_contact_info p a {
    color: #8fa3b4;
}

.services_list {
    padding-left: 16px;
    /* Adjust the value as needed */
}

.widget_box_1 .news_post .news_single {
    margin-bottom: 20px;
    overflow: hidden;
}

.widget_box_1 .news_post .news_single .news_thumb {
    overflow: hidden;
    clear: both;
    float: left;
    width: 100px;
}

.widget_box_1 .news_post .news_single .news_thumb img {
    width: 100%;
}

.widget_box_1 .news_post .news_single .news_link {
    margin-left: 120px;
}

.widget_box_1 .news_post .news_single .news_link h3 {
    font-size: 18px;
    line-height: 24px;
}

.widget_box_1 .news_post .news_single .news_link h3 a {
    color: #fff;
}

.widget_box_1 .news_post .news_single .news_link a.post_admin {
    color: #8fa3b4;
    font-size: 14px;
    text-transform: capitalize;
}

.widget_box_1 .news_post .news_single .news_link a.post_admin i {
    margin-right: 10px;
}

/*
	End onova_footer_widget section
*/

/*
	Start onova_footer css
*/
.onova_footer_1 {
    background: #05121d;
    padding: 26px 0;
}

.onova_footer_1 .copyright_text p {
    font-size: 14px;
    color: #8fa3b4;
}

.onova_footer_1 .footer_link {
    float: right;
}

.onova_footer_1 .footer_link ul li {
    display: inline-block;
    margin-left: 47px;
}

.onova_footer_1 .footer_link ul li a {
    font-size: 14px;
    color: #8fa3b4;
}

.onova_footer_1 .footer_link ul li a:hover;

.onova_footer_1 .footer_link ul li a:focus {
    color: #8fa3b4;
}

/*
	End onova_footer css
*/

/*==========================================
	onova INDEX 2 CSS
===========================================*/


/*
	Start onova_header css
*/
.onova_header_2 {
    position: absolute;
    z-index: 337;
    width: 100%;
    top: 0px;
}

.onova_header_2.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #222;
}



.onova_header_2.sticky .main_menu ul>li>a {
    padding: 27px 0;
}

.header_container_2 {
    max-width: 1460px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
}

.onova_logo_2 ul li {
    display: inline-block;
    margin-right: 15px;
}

.onova_logo_2 ul li a.menu_link {
    font-size: 20px;
    color: #ffffff;
}

.onova_main_menu_2>ul>li {
    padding: 0 25px 0 25px;
}

.onova_main_menu_2 .main_menu ul li a {
    color: #fff;
    text-transform: capitalize;
}

.logo_header_2 {
    filter: grayscale(100%) brightness(30%);
}

.menu_get_button {
    display: inline-flex;
    align-items: end;
    margin-left: 75px;
}

.menu_get_button .get_btn_icon {
    float: left;
}

.menu_get_button .get_btn_icon i {
    color: #ffec1c;
    font-size: 36px;
}

.get_btn_info {
    margin-left: 20px;
}

.get_btn_info h5 {
    font-size: 18px;
    color: #fff;
    line-height: 28px;
    text-transform: uppercase;
}

.get_btn_info a {
    color: #d4dee0;
    font-size: 16px;
}

/*
	End onova_header css
*/

/*
	Start onova_main section css
*/
.single_slider {
    padding: 335px 0 400px;
    position: relative;
}

.onova_overley_2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.322;
    background: rgb(13, 35, 53);
}

.onova_main_content_2 span {
    font-size: 26px;
    color: #fff;
    display: block;
    margin-bottom: 25px;
}

.onova_main_content_2 h1 {
    color: #fff;
    font-size: 100px;
    line-height: 90px;
}

.onova_main_content_2 {
    margin-bottom: 32px;
}

.onova_btn_2 {
    padding: 16px 30px;
    margin-right: 30px;
    border-radius: 0;
}

.onova_btn_2:after {
    content: '\f061';
    font-size: 14px;
    display: inline-block;
    font-family: 'Font Awesome 5 Pro';
    margin-left: 25px;
}

/*
	End onova_main section css
*/

/*
	Start onova_categories section
*/
.onova_categories_1 {
    position: relative;
}

.categories_main {
    margin-top: -160px;
    padding: 100px 50px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 14px 28px 0px rgba(93, 93, 93, 0.04);
}

.categories_main .categories_box {
    text-align: center;
}

.categories_main .categories_box:hover .categories_icon span.icon_hover {
    opacity: 1;
    transform: scale(1);
}

.categories_icon {
    position: relative;
}

.categories_main .categories_box .categories_icon i {
    font-size: 70px;
    color: #0574ff;
    margin-bottom: 40px;
}

.categories_main .categories_box .categories_icon span.icon_hover {
    font-size: 300px;
    color: #f8fbff;
    position: absolute;
    top: -80px;
    left: 0;
    opacity: 0;
    transform: scale(.5);
    z-index: -1;
    transition: all .5s ease-in;
}

.categories_main .categories_box .categories_info {
    z-index: 1;
    position: relative;
}

.categories_main .categories_box .categories_info span.dot_c {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: rgb(255, 255, 255);
    border: 4px solid rgb(0, 102, 255);
    border-radius: 50%;
    margin-bottom: 35px;
}

.categories_main .categories_box .categories_info span.dot_c:after {
    content: '';
    width: 360px;
    height: 2px;
    position: absolute;
    top: 7px;
    background-color: #e2f0ff;
    display: block;
    z-index: -1;
}

.categories_main .categories_box .categories_info span.dot_c_3:after {
    display: none;
}

.categories_main .categories_box .categories_info h3 {
    margin-bottom: 20px;
    font-size: 35px;
    font-weight: bold;
}

.categories_main .categories_box .categories_info p {
    margin-bottom: 32px;
}

.categories_main .categories_box .categories_info .categories_btn {
    display: inline-block;
    width: 63px;
    height: 63px;
    line-height: 55px;
    text-align: center;
    font-size: 16px;
    color: #758ca0;
    border: 4px solid rgb(243, 248, 255);
    background-color: rgb(255, 255, 255);
    transition: all .5s ease-in;
}

.categories_main .categories_box .categories_info .categories_btn:hover,
.categories_main .categories_box .categories_info .categories_btn:focus {
    border-color: #0066ff;
    color: #0066ff;
}

/*
	Start onova_categories section
*/

/*
	Start onova_about section
*/
.onova_about_2 {
    padding: 120px 0 75px;
}

.onova_about.onova_about_1 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.onova_left_box {
    position: relative;
}

.about_img_2 {
    position: absolute;
    bottom: -80px;
    right: 0;
    border: 8px solid rgb(255, 255, 255);
    background: rgb(226, 226, 226);
    box-shadow: 0px 4px 8px 0px rgba(206, 216, 230, 0.3);
}

.about_img_1 {
    position: relative;
}

.about_img_1 .overlay_qoute {
    position: absolute;
    right: 20%;
    display: block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background: rgb(5, 116, 255);
    top: 40px;
    color: #fff;
    font-size: 20px;
}

.onova_about_service {
    padding-top: 120px;
    padding-bottom: 120px;
}

.onova_right_box .about_right_content {
    margin-bottom: 55px;
}

.onova_right_box .about_right_content span {
    margin-bottom: 20px;
}

.onova_right_box .about_right_content h4 {
    font-size: 26px;
    line-height: 36px;
    color: #758ca0;
    font-family: 'Karla';
}

.onova_head_box_1.onova_head_box_2 {
    display: block;
}

.onova_head_box_1.onova_head_box_2 .head_box_1 {
    width: 100%;
    margin-bottom: 37px;
}

/*
	Start onova_about section
*/

/*
	Start onova_video section css
*/
.onova_video_2 {
    position: relative;
    padding-top: 110px;
    padding-bottom: 20px;
}

.onova_portfolio {
    padding-top: 110px;
    padding-bottom: 120px;
}

.onova_portfolio .theme_title {
    margin-bottom: 70px;
}

.onova_left_box .video_content span {
    text-transform: uppercase;
    letter-spacing: 2px;
}

.onova_left_box .video_content h2 {
    color: #fff;
}

.video_content_img {
    position: relative;
}

.onova_video.onova_video_4 .video_content_img img {
    width: 100%;
}

.onova_video.onova_video_4 {
    padding-top: 18px;
    padding-bottom: 17px;
}

.video_content_img_2 {
    position: relative;
    margin-top: 80px;
    margin-bottom: -165px;
    text-align: center;
}

.video_content_img img {
    border: 16px solid #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.13);
}

.video_button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video_content_img_2 .video_button a.play_btn:after,
.video_content_img_2 .video_button a.play_btn:before {
    border-color: #fff;
}

/*
	End onova_video section css
*/


/*
	Start onova_service section css
*/
.onova_service_2 {
    padding-top: 257px;
    margin-bottom: -200px;
}

.service_box.service_box_2,
.service_box.service_box_4 {
    margin-left: 20px;
    margin-right: 20px;
}

.service_box_2 {
    padding: 80px 70px;
    border: 4px solid rgb(244, 249, 255);
    background: rgb(255, 255, 255);
    transition: all linear 0.3s;
}

.service_box_2:hover {
    border-color: #1A7EFB;
}

.service_box_2 h3 {
    font-size: 30px;
    justify-content: start;
    display: flex;
    margin-bottom: 25px;
}

.service_box_2 h3 span {
    font-size: 60px;
    color: #0574ff;
    margin-right: 20px;
}

.service_box_2 p {
    margin-bottom: 50px;
}

.service_body ul.service_features li {
    line-height: 50px;
}

.service_body ul.service_features li i {
    font-size: 20px;
    color: #0574ff;
    margin-right: 22px;
}

.service_slide {
    z-index: 1;
}

.service_slide .slick-next {
    top: -30%;
    right: 11%;
}

.service_slide .slick-prev {
    top: -30%;
    right: 17%;
}

/*
	End onova_service section css
*/

/*
	Start onova_portfolio section css
*/
.onova_portfolio_home {
    position: relative;
    padding-top: 312px;
    padding-bottom: 20px;
    background-repeat: repeat;
    /* clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%); */
}

.portfolio_main_wrapper {
    position: relative;
    z-index: 1;
}

.onova_portfolio_home .theme_title span,
.onova_portfolio_home .theme_title h2 {
    color: #fff;
}

.onova_portfolio_home .portfolio_button .portfolio_btn {
    color: #fff;
}

.onova_counterup_1 {
    padding-top: 32px;
    margin-bottom: -150px;
    margin-top: -13px;
}

.counterup_container {
    max-width: 1295px;
    margin: 0 auto;
    padding: 110px;
    border: 10px solid rgb(5, 116, 255);
    background: rgb(255, 255, 255);
}

.counter_box_1 {
    display: flex;
}

.counter_box_1 .counter_icon i {
    color: #0574ff;
    font-size: 50px;
}

.counter_box_1 .counter_info {
    margin-left: 32px;
}

.counter_box_1 .counter_info h2 {
    font-size: 40px;
    margin-bottom: 15px;
}

@media only and (min-width: 1170px) {
    .counterup_container {
        width: 100%;
    }
}

/*
	End onova_portfolio section css
*/

/*
	Start onova_testimonial section css
*/
.onova_testimonial {
    position: relative;
}

.onova_testimonial_1 {
    padding-bottom: 120px;
    padding-top: 250px;
}

.testimonial_container {
    max-width: 1230px;
    margin: auto;
}

.testimonial_item {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.testimonial_img {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.tesimonial_content {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.testimonial_img,
.tesimonial_content {
    width: 50%;
    float: left;
}

.testimonial_img img {
    width: 100%;
}

.content_title {
    margin-bottom: 25px;
}

.content_title span {
    color: #0574ff;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.content_title span:after {
    display: inline-block;
    content: '';
    width: 150px;
    height: 2px;
    background: rgb(5, 116, 255);
    opacity: 0.4;
    margin-left: 20px;
    margin-bottom: 5px;
}

.tesimonial_content {
    padding-left: 55px;
}

.content_box h2 {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 30px;
}

.content_box h3 {
    margin-bottom: 15px;
}

.content_box p {
    margin-bottom: 30px;
}

.content_box h6 {
    color: #0574ff;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.testimonial_slide_1 .slick-next {
    position: absolute;
    bottom: 5%;
    right: 7%;
}

.testimonial_slide_1 .slick-prev {
    position: absolute;
    bottom: 5%;
    right: 14%;
    z-index: 1;
}

.choose_slide_1 .slick-next:before,
.choose_slide_1 .slick-prev:before,
.service_slide .slick-next:before,
.service_slide .slick-prev:before,
.testimonial_slide_1 .slick-next:before,
.testimonial_slide_1 .slick-prev:before {
    display: block;
    font-family: 'Font Awesome 5 Pro';
    width: 60px;
    height: 60px;
    line-height: 55px;
    text-align: center;
    background: transparent;
    color: #758ca0;
    border: 4px solid rgb(244, 249, 255);
    transition: all .5s;
}

.choose_slide_1 .slick-next:after,
.choose_slide_1 .slick-prev:after,
.service_slide .slick-next:after,
.service_slide .slick-prev:after,
.testimonial_slide_1 .slick-next:after,
.testimonial_slide_1 .slick-prev:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 0px;
    height: 0px;
    border: 4px solid rgb(244, 249, 255);
    visibility: hidden;
    opacity: 0;
    transition: all .5s;
}

.choose_slide_1 .slick-next:hover:after,
.choose_slide_1 .slick-prev:hover:after,
.service_slide .slick-next:hover:after,
.service_slide .slick-prev:hover:after,
.testimonial_slide_1 .slick-next:hover:after,
.testimonial_slide_1 .slick-prev:hover:after {
    visibility: visible;
    opacity: 1;
    width: 60px;
    height: 60px;
    border-color: #0574ff;
}

.choose_slide_1 .slick-next:before,
.service_slide .slick-next:before,
.testimonial_slide_1 .slick-next:before {
    content: '\f105';
}

.choose_slide_1 .slick-prev:before,
.service_slide .slick-prev:before,
.testimonial_slide_1 .slick-prev:before {
    content: '\f104';
}

/*
	End onova_testimonial section css
*/


/*
	Start onova_skills section css
*/
.onova_skill_area {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: -85px;
}

.onova_skill_area .skill_left {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.onova_skill_area .skill_right {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.onova_skill_area .skill_left,
.onova_skill_area .skill_right {
    width: 50%;
}

.onova_skill_area .skill_left {
    background: #0d2335;
    padding: 120px;
}

.onova_skill_area .skill_left .skill_content {
    margin-bottom: 50px;
}

.onova_skill_area .skill_left .skill_content span {
    display: block;
    letter-spacing: 2px;
    color: #aac0d2;
    font-weight: bold;
    margin-bottom: 20px;
}

.onova_skill_area .skill_left .skill_content h2 {
    color: #fff;
}

.onova_skill_area .skill_left .progress_area .progess_single {
    margin-bottom: 45px;
}

.onova_skill_area .skill_left .progress_area .progess_single:last-child {
    margin-bottom: 0;
}

.onova_skill_area .skill_left .progress_area .progress-title h5 {
    font-size: 14px;
    font-family: 'Karla';
    color: #fff;
    margin-bottom: 19px;
}

.onova_skill_area .skill_left .progress_area .progress {
    background: rgb(34, 62, 85);
    opacity: 0.502;
    height: 4px;
    border-radius: 0;
}

.onova_skill_area .skill_left .progress_area .progress .progress-bar {
    height: 4px;
    border-radius: 0;
    transition: all .5s;
    animation-delay: 1s;
}

.onova_skill_area .skill_left .progress_area .progress .progress-bar.color_1 {
    background: rgb(38, 207, 255);
}

.onova_skill_area .skill_left .progress_area .progress .progress-bar.color_2 {
    background: rgb(255, 212, 38);
}

.onova_skill_area .skill_left .progress_area .progress .progress-bar.color_3 {
    background: rgb(255, 50, 93);
}

.onova_skill_area .skill_right {
    background: url(../images/skill_1.png)no-repeat center;
    background-size: cover;
    height: 670px;
}

/*
	Start onova_skills section css
*/

/*
	Start onova_sponser section css
*/
.onova_sponser_1 {
    padding-top: 220px;
    padding-bottom: 120px;
}

.single_sponser img {
    margin: 0 auto;
}

/*
	End onova_sponser section css
*/

/*
	Start onova_blog_2 section css
*/
.onova_blog_2 {
    padding-top: 112px;
    padding-bottom: 20px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.blog_area_2 {
    margin-bottom: -200px;
}

.blog_box_2 {
    background: #fff;
}

.blog_box_2 .blog_info {
    padding: 40px;
}

.blog_box_2 .blog_info .post_meta {
    border: none;
}

.blog_box_2 .blog_info .post_meta ul li {
    margin-left: 0;
}

.blog_box_2 .blog_info .post_text h3 {
    font-size: 24px;
}

.blog_box_2 .blog_info .post_tag {
    margin-top: 25px;
}

.blog_box_2 .blog_info .post_tag ul li {
    display: inline-block;
    margin-right: 30px;
}

.blog_box_2 .blog_info .post_tag ul li a.tag {
    float: left;
    font-size: 14px;
    color: #0574ff;
    text-transform: uppercase;
    font-weight: bold;
}

.blog_box_2 .blog_info .post_tag ul li:last-child {
    float: right;
}

/*
	End onova_blog_2 section css
*/

/*
	Start call_to_action seciton css
*/
.call_to_action_2 {
    padding: 295px 0 120px;
}

.call_to_action_2 .theme_title {
    margin-bottom: 58px;
}

/*
	End call_to_action seciton css
*/

/*
	Start onova_footer_widget section css
*/
.onova_footer_widget {
    position: relative;
}

.white_bg {
    background: #fff;
}

.widget_area_2 .footer_title h5 {
    color: #0d2335;
}

.widget_top_area_2 {
    border-color: #e7f4ff;
}

.widget_top_area_2 .footer_social ul li a {
    border-radius: 0;
    background: #fff;
    border: 4px solid rgb(234, 245, 255);
}

.widget_top_area_2 .footer_social ul li a:hover {
    border-color: rgb(5, 116, 255);
    color: #0574ff;
    background: #fff;
}

.widget_area_2 .widget_box_1 .news_post .news_single .news_link h3 a {
    color: #0d2335;
}

/*
	End onova_footer_widget section css
*/
/*==========================================
	End Onova INDEX 2 CSS
===========================================*/


/*==========================================
	Start Onova INDEX 3 CSS
===========================================*/
.onova_header_3 {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 337;
}

.onova_header_3.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #222;
}




.onova_header_3.sticky .main_menu ul li>a {
    padding: 28px 0;
}



.header_container_3 {
    max-width: 1400px;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
}

.onova_main_menu_3 .main_menu ul li a {
    color: #fff;
    text-transform: capitalize;
}

.menu_get_right {
    display: flex;
}

.menu_get_right .search_icon {
    margin-right: 30px;
}

.menu_get_right .menu_get_icon a {
    color: #fff;
    font-size: 16px;
}

/*
	Start onova_main section css
*/
.onova_main_3 {
    padding: 220px 0 120px;
}

.main_container.main_container_2 {
    max-width: 1400px;
}

.main_right .main_img img {
    width: 100%;
}

.main_container_2 .onova_content h1,
.main_container_2 .onova_content span,
.main_container_2 .onova_content p {
    color: #fff;
}

.main_container_2 .onova_content span {
    font-size: 26px;
    display: inline-block;
}

.main_container_2 .onova_content p {
    margin-bottom: 40px;
}

.onova_main_button .onova_btn_3 {
    margin-right: 30px;
    padding: 17px 30px;
    background: #00166a;
}

.onova_main_button .onova_btn_3:hover,
.onova_main_button .onova_btn_3.play_btn:hover,
.onova_main_button .onova_btn_3.play_btn:focus,
.onova_main_button .onova_btn_3:focus {
    background: #0574ff;
    color: #fff;
    box-shadow: 0px 16px 32px 0px rgba(54, 85, 125, 0.3);
}

.onova_main_button .onova_btn_3.play_btn {
    background: transparent;
    border-radius: 50%;
    border: 2px solid #fff;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    padding: 0;
    font-size: 14px;
    transition: all .5s;
}

/*
	End onova_main section css
*/

/*
	Start about_service section css
*/
.about_service_box {

    float: left;
    width: 46.35%;
    /* text-align: center; */
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 8px 16px 0px rgba(0, 65, 147, 0.06);
    border-bottom: 2px solid;
    transition: all .5s;
    margin-right: 20px;
    z-index: 2;
    overflow: hidden;
}

.about_service_box .overlay {
    padding: 50px 20px;
    position: relative;
    z-index: 4;
}



.about_service_box .overlay::before {
    position: absolute;
    content: '';
    height: 0%;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #0574ff;
    z-index: -1;
    transition: all linear 0.3s;

}

.about_service_box:hover .overlay::before {
    opacity: 1;
    height: 100%;
}

.about_service_box:hover .overlay .service_info h4,
.about_service_box:hover .overlay .service_info p {
    color: #fff;
}

.about_service_box:hover .overlay .service_icon .icon-white {
    display: inline-block;
}

.about_service_box:hover .overlay .service_icon .icon-theme {
    display: none;
}

.about_service_box .service_icon {
    margin-bottom: 45px;
}

.about_service_box .service_info h4 {
    font-size: 26px;
    margin-bottom: 20px;
    transition: all linear 0.3s;
}

.about_service_box .service_info p {
    transition: all linear 0.3s;
}

.icon-white {
    display: none;
}

.about_service_box.hb_2 .overlay::before {
    background: #ff9600;
}

.about_service_box.hb_3 .overlay::before {
    background: #28c6b8;
}

.about_service_box.hb_4 .overlay::before {
    background: #ff6c6c;
}









.hb_3 {
    border-color: #28c6b8;
}

.hb_1 {
    border-color: #0574ff;
}

.hb_2 {
    margin-top: 40px;

    border-color: #ff9600;

}

.hb_4 {
    border-color: #ff6c6c;
    margin-top: 40px;
}

/*
	End about_service section
*/

.onova_faq.onova_same_faq.onova_faq_2 {
    padding-top: 120px;
    padding-bottom: 100px;
}

.onova_faq.onova_same_faq.onova_faq_3 {
    padding-bottom: 120px;
    padding-top: 120px;
}

.onova_faq_area_4 {
    position: relative;
}

.onova_faq_4 .onova_faq_area .faq_right {
    display: inline-flex;
    align-items: center;
    background: #17bfa4;
}

.section_padding.onova_portfolio_4 {
    padding: 110px 0 60px;
}

.portfolio_box.portfolio_box_4 {
    margin-bottom: 60px !important;
}

.why_choose_2 {
    padding-top: 95px;
}

.onova_video_4 .video_button a.play_btn:after {
    border-color: #fff;
}

.onova_video_4 .video_button a.play_btn:before {
    border-color: #fff;
}

.choose_slide_1 .slick-next {
    top: -15%;
    right: 5%;
}

.choose_slide_1 .slick-prev {
    top: -15%;
    right: 13%;
}

.choose_box_2 {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    overflow: hidden;
    transition: all .5s;
}

.choose_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .8;
}

.choose_box_2 .choose_info {
    position: relative;
    backface-visibility: hidden;
    box-sizing: border-box;
    transition: all .5s;
}

.choose_box_2 .choose_info .choose_content_2 {
    position: relative;
    padding-top: 280px;
    padding-bottom: 50px;
    text-align: center;
}

.choose_box_2 .choose_info .choose_content_2 h4 {
    color: #fff;
}

.choose_box_2 .choose_info .choose_content_2 i {
    color: #fff;
    font-size: 60px;
    margin-bottom: 22px;
}

.choose_box_2 .choose_box_bg .choose_content_1 {
    position: relative;
    /* text-align: center; */
    padding: 20px;
    padding-top: 25%;
}

.choose_box_2 .choose_box_bg .choose_content_1 i {
    font-size: 60px;
    color: #fff;
    margin-bottom: 30px;
}

.choose_box_2 .choose_box_bg .choose_content_1 h4 {
    color: #fff;
    margin-bottom: 15px;
}

.choose_box_2 .choose_box_bg .choose_content_1 p {
    margin-bottom: 25px;
    color: #fff;
}

.choose_box_2 .choose_box_bg .choose_content_1 span.choose_btn {
    color: #fff;
    font-size: 16px;
}

.choose_box_2 .choose_box_bg {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    transform: rotateY(90deg); */
    -o-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    /* opacity: 0; */
    transition: all .5s ease;
}

.choose_box_2:hover .choose_box_bg {
    opacity: 1;
    transform: rotateY(0deg);
}

.choose_box_2 .choose_box_bg:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* rgba(26, 126, 251, 0.9); */
}

.choose_box_2 a.choose_box_bg span.choose_icon_box i {
    font-size: 70px;
    color: #fff;
}


.choose_box_2 .choose_box_bg .choose_icon_box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.choose_box_2:hover .choose_info {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    opacity: 0;
}

/*
	Satrt onova_sponser section
*/
.onova_sponser_2 {
    padding: 120px 0 20px;
}

.dark_bg {
    background: #0d2335;
}

.sponser_contact_action {
    margin-top: 100px;
    margin-bottom: -180px;
}

.sponser_contact_box {
    background: #fff;
    display: flex;
    align-items: stretch;
}

.bg_color_1 {
    background: #6fe7f1;
}

.bg_color_2 {
    background: #fbe8de;
}

.contact_box_left {
    display: inline-flex;
    align-items: center;
}

.contact_box_right .contact_box_info {
    padding: 50px;
    background: #fff;
}

.contact_box_right .contact_box_info i {
    display: block;
    font-size: 40px;
    color: #0574ff;
    margin-bottom: 23px;
}

.contact_box_right .contact_box_info h3 {
    font-size: 30px;
    margin-bottom: 15px;
}

.contact_box_right .contact_box_info p {
    margin-bottom: 22px;
    font-size: 14px;
}

.contact_box_right .contact_box_info a.mailto {
    font-size: 18px;
    color: #ff9232;
}

.contact_box_right .contact_box_info h5 {
    color: #ff5f5f;
}

/*
	Start onova_contact section
*/
.onova_contact_2 {
    padding: 270px 0 120px;
}

.onova_contact_2 .theme_title {
    margin-bottom: 56px;
}

/*
	End onova_contact section
*/
.footer_widget_3 .footer_title h5 {
    color: #fff;
}

.footer_widget_3 .footer_social ul li a {
    border-radius: 0;
}

/*==========================================
	End Onova INDEX 3 CSS
===========================================*/


/*
	Start onova_breadcrumb section css
*/
.onova_breadcrumb {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 247px 0 150px;
}

.onova_breadcrumb img.onova_text {
    position: absolute;
    bottom: 0;
    left: 120px;
    z-index: 1;
}

.onova_overlay_2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(13, 35, 53);
    opacity: 0.322;
}

.breadcrumb_text {
    position: relative;
    z-index: 1;
}

.breadcrumb_text h2 {
    font-size: 60px;
    line-height: 70px;
    color: #fff;
    margin-bottom: 20px;
}

.breadcrumb_text h1 {
    font-size: 60px;
    line-height: 70px;
    color: #fff;
    margin-bottom: 20px;
}

.breadcrumb_text ul li {
    display: inline-block;
}

.breadcrumb_text ul li a {
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
}

.breadcrumb_text ul li:after {
    content: '|';
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
}

.breadcrumb_text ul li:last-child:after {
    display: none;
}

/*
	End onova_breadcrumb section css
*/


/*
	Start about.html css
*/
.onova_about_3 .about_img_3 {
    position: relative;
}

.onova_about_3 .about_img_3 .overlay_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0574ff;
    opacity: .8;
}

.onova_about_3 .about_img_3 .overlay_content {
    position: absolute;
    bottom: 60px;
    left: 60px;
}

.onova_about_3 .about_img_3 .overlay_content h2 {
    color: #fff;
    font-size: 100px;
}

.onova_about_3 .about_img_3 .overlay_content p {
    color: #fff;
}

.onova_about_3 .about_content_3 h2 {
    margin-bottom: 20px;
}

.onova_about_3 .about_content_3 h4 {
    font-size: 26px;
    line-height: 36px;
    color: #758ca0;
    font-family: 'Karla';
    margin-bottom: 30px;
}

.onova_about_3 .about_content_3 p {
    margin-bottom: 35px;
}

.onova_about_3 .onova_button .onova_btn {
    border-radius: 0;
    margin-right: 25px;
}

.onova_about_3 .onova_button .play_btn {
    background: transparent;
    border: 1px solid #b4bfc9;
    font-size: 14px;
    color: #0d2335;
    padding: 14px 22px;
}

.onova_about_3 .onova_button .play_btn:hover,
.onova_about_3 .onova_button .play_btn:focus {
    background: #0066ff;
    color: #fff;
}

.onova_service_3 {
    padding: 110px 0 10px;
}

.service_box_3 {
    position: relative;
    padding: 80px 40px;
    text-align: center;
    background: rgb(255, 255, 255);
    box-shadow: 0px 10px 20px 0px rgba(5, 116, 255, 0.06);
}

.service_box_3 .categories_icon {
    margin-bottom: 40px;
}

.service_box_3 .categories_info .dot_c_2 {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    border: 4px solid rgb(0, 102, 255);
}

.service_box_3 .categories_info h3 {
    margin-top: 40px;
    font-size: 30px;
    margin-bottom: 15px;
}

.service_box_3 .categories_info .categories_btn {
    position: absolute;
    bottom: 0;
    display: inline-block;
    width: 74px;
    height: 74px;
    line-height: 74px;
    text-align: center;
    border: 1px solid rgb(230, 238, 242);
    background: rgb(255, 255, 255);
    left: 50%;
    transform: translate(-50%, 50%);
    transition: all .5s;
}

.service_box_3 .categories_info .categories_btn:hover,
.service_box_3 .categories_info .categories_btn:focus {
    background: #0061da;
    color: #fff;
}

.onova_about_counterup {
    margin-top: 124px;
    margin-bottom: -170px;
    z-index: 1;
    position: relative;
}

.onova_video_3 {
    padding-bottom: 107px;
}

.about_video_main {
    margin-top: 230px;
}

.about_video_main .video_play .play_btn {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    background: rgb(5, 116, 255);
    color: #fff;
    font-size: 20px;
}

.about_video_main .video_content {
    margin-top: 170px;
}

.about_video_main .video_content h2 {
    color: #fff;
}

.onova_faq_1 {
    padding: 120px 0;
}

.onova_faq.onova_faq_1.onova_faq_4 {
    padding: 120px 0 103px;
}

.onova_faq_1 .onova_faq_area {
    display: flex;
    align-items: stretch;
}

.onova_faq_1 .faq_left,
.onova_faq_1 .faq_right {
    width: 50%;
}

.onova_faq_1 .faq_right {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.onova_faq_1 .faq_left {
    background: #0d2335;
    padding: 110px 120px 120px;
}

.onova_faq.onova_faq_1.onova_faq_4 {
    padding: 120px 0 103px;
}

.onova_faq_1 .faq_left .faq_content {
    margin-bottom: 50px;
}

.onova_faq_1 .faq_left .faq_content h2 {
    color: #fff;
}

.onova_faq_1 .faq_left .card {
    border: 1px solid #304e66;
    border-radius: 0;
    background: transparent;
}

.onova_faq_1 .faq_left .card-header {
    padding: 15px 20px;
}

.onova_faq_1 .faq_left .card-header h5 a {
    font-size: 16px;
    font-family: 'Karla';
    color: #fff;
}

.onova_faq_1 .faq_left .card-header h5 a span {
    margin-right: 20px;
    color: #0061da;
}

/*
	End about.html css
*/

/*
	Start history.html css
*/

.onova_history {
    padding-bottom: 120px;
    padding-top: 110px;
}

.onova_history .theme_title {
    margin-bottom: 56px
}

.top_text h2 {
    color: #0574ff;
    font-size: 40px;
    line-height: 50px;
}

.history_row {
    display: flex;
    align-items: center;
}

.history_row .history_discription,
.history_row .history_item {
    width: 50%;
}

.history_box {
    padding: 50px 40px;
}

.history_row .history_discription:nth-child(odd) .history_box_left {
    text-align: right;
    border-right: 1px solid #efefef;
}

.history_row .history_discription:nth-child(even) .history_box_left {
    text-align: left;
    border-left: 1px solid #efefef;
}

.history_row .history_discription .history_box_left h2 {
    font-size: 40px;
    line-height: 50px;
}

.bottom_text a {
    display: inline-block;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    border: 1px solid rgb(239, 239, 239);
    background-color: rgb(255, 255, 255);
    color: #0574ff;
    font-size: 40px;
    transition: all .5s;
}

.bottom_text a:hover,
.bottom_text a:focus {
    background: #0574ff;
    color: #fff;
}

/*
	End history.html css
*/

/*
	Start it_service.html css
*/
.onova_it_service.onova_it_service_1 {
    padding-top: 120px;
    padding-bottom: 90px;
}

.onova_it_service_1 .service_box_1 {
    margin-bottom: 30px;
}

.service_box_1 {
    transition: all .5s;
}

.service_box_1 .service_img img {
    width: 100%;
}

.service_icon span.pe-7s-arc {
    color: #ff900c;
}

.service_icon span.pe-7s-hammer {
    color: #e06483;
}

.service_icon span.pe-7s-refresh-2 {
    color: #6caf4f;
}

.service_icon span.pe-7s-wristwatch {
    color: #6305ff;
}

.service_icon span.pe-7s-wallet {
    color: #e06483;
}

/*
	End it_service.html css
*/


/*
	Start it_consultancy.html css
*/
.onova_service.onova_service_4.section_padding {
    padding-bottom: 120px;
}

.onova_counterup_3 {
    padding-top: 113px;
    padding-bottom: 110px;
}

.counter_title {
    margin-bottom: 40px;
}

.counter_title p {
    text-decoration: underline;
    color: #0061da;
    font-size: 18px;
    font-weight: bold;
}

.service_box_4 {
    position: relative;
    border: 4px solid rgb(244, 249, 255);
    background: rgb(255, 255, 255);
    z-index: 1;
}

.service_box_4 .service_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
}

.service_box_4:hover .service_bg {
    opacity: 1;
}

.service_box_4:hover .service_bg:after {
    opacity: .8;
}

.service_box_4 .service_bg:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0061da;
    opacity: .8;
    z-index: -1;
    opacity: 0;
    transition: all .5s;
}

.service_box_4 .service_head {
    padding: 70px 30px;
}

.service_box_4 .service_head i {
    font-size: 60px;
    color: #0574ff;
    margin-bottom: 40px;
}

.service_box_4 .service_head h3 {
    font-size: 26px;
    margin-bottom: 30px;
}

.service_box_4:hover .service_head i,
.service_box_4:hover .service_head h3,
.service_box_4:hover .service_head p {
    color: #fff;
}

.onova_testimonial_2 .testimonial_slide_1 {
    padding-top: 120px;
}

.onova_testimonial_2 .testimonial_slide_1 .slick-next {
    bottom: 20%;
}

.onova_testimonial_2 .testimonial_slide_1 .content_box h2,
.onova_testimonial_2 .testimonial_slide_1 .content_box h3 {
    color: #fff;
}

.onova_testimonial_2 .testimonial_slide_1 .slick-prev {
    bottom: 20%;
}

.onova_testimonial_2 .testimonial_slide_1 .slick-next:before,
.onova_testimonial_2 .testimonial_slide_1 .slick-prev:before {
    border-color: #2d4b63;
}

.pricing_box_1 {
    padding: 50px 40px;
    position: relative;
    z-index: 1;
}

.onova_pricing {
    padding-top: 52px;
    padding-bottom: 155px;
}

.onova_pricing.onova_pricing_4 {
    padding-top: 110px;
}

.pricing_box_1 .pricing_head span {
    display: block;
    width: 111px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #ccc;
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    margin-bottom: 30px;
}

.pricing_box_1 .pricing_head span.color_1 {
    background: #ff5f5f;
}

.pricing_box_1 .pricing_head span.color_2 {
    background: #5f79ff;
}

.pricing_box_1 .pricing_head span.color_3 {
    background: #ff945f;
}

.pricing_box_1 .pricing_head h3 {
    font-size: 28px;
    margin-bottom: 10px;
}

.pricing_box_1:hover .pricing_head h3 {
    color: #fff;
}

.pricing_box_1 .pricing_head p {
    margin-bottom: 30px;
}

.pricing_box_1:hover .pricing_head p {
    color: #fff;
}

.pricing_box_1 .price_tag h2 {
    font-size: 40px;
    margin-bottom: 30px;
}

.pricing_box_1:hover .price_tag h2 {
    color: #fff;
}

.pricing_box_1:hover .price_tag h2 span {
    color: #fff;
}

.pricing_box_1 .price_tag h2 span {
    font-size: 16px;
    color: #758ca0;
}

.pricing_box_1 .pricing_body {
    padding: 40px;
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 65, 147, 0.06);
    border-radius: 6px;
    margin-bottom: 25px;
}

.pricing_box_1 .pricing_btn {
    position: absolute;
    bottom: -35px;
    left: 0;
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    color: #758ca0;
    font-size: 16px;
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 65, 147, 0.06);
    transition: all .5s;
    right: 0;
    margin: 0 auto;
}

.pricing_box_1:hover .pricing_btn {
    background: #0574ff;
    color: #fff;
}

.pricing_box_1:hover .pricing_btn:before {
    opacity: 1;
}

.pricing_box_1 .pricing_btn:before {
    position: absolute;
    top: -10px;
    left: -10px;
    display: inline-block;
    content: '';
    height: 90px;
    width: 90px;
    line-height: 90px;
    border: 1px solid #0574ff;
    background: #fff;
    opacity: 0;
    z-index: -1;
}

.pricing_img_1 {
    background: url(../images/pricing_bg.png) repeat;
    background-position: center;
}

.pricing_box_1:hover .pricing_img_1 {
    opacity: 0;
}

.pricing_box_1:hover .pricing_img_2 {
    opacity: 1;
}

.pricing_box_1 .pricing_img_2 {
    opacity: 0;
    z-index: -1;
}

.pricing_box_1 .pricing_img_2:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0d2335;
    opacity: 0.9;
    z-index: 1;
}

.pricing_box_1 .pricing_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: all .5s;
}

.onova_map_1 {
    background: #0574ff;
    position: relative;
    padding-top: 120px;
    padding-bottom: 120px;
}

.map_pattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/map_pattern.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.map_left_box .map_box {
    margin-bottom: 120px;
}

.map_box {
    display: flex;
    padding: 40px 40px 40px 60px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 16px 32px 0px rgba(0, 65, 147, 0.2);
}

.map_box .icon i {
    font-size: 50px;
    color: #0574ff;
}

.map_box .icon_info {
    padding-left: 40px;
}

.map_box .icon_info h2 {
    font-size: 30px;
}

.map_right_box .map_img_box {
    text-align: center;
}

/*
	End it_consultancy.html css
*/



/*
	Start faq.html css
*/
.onova_same_faq .faq_accoridian .card {
    margin-bottom: 20px;
    border-radius: 0;
    border: none;
}

.onova_same_faq .faq_accoridian .card-header {
    padding: 20px 30px 20px 20px;
    background: transparent;
    border: 2px solid #e0e9f4;
}

.onova_same_faq .faq_accoridian .card-header h5 {
    font-size: 16px;
}

.onova_same_faq .faq_accoridian .card .card-header h5 a i.far {
    display: none;
    color: #0d2335;
    font-size: 16px;
    cursor: pointer;
    margin-right: 20px;
}

.faq_accoridian .card .card-header h5 a i.far {
    display: none;
}

.faq_accoridian .card .card-header h5 a[aria-expanded="true"] i.far.fa-minus {
    display: inline-block;
}

.faq_accoridian .card .card-header h5 a[aria-expanded="false"] i.far.fa-plus {
    display: inline-block;
}

.choose_info {
    position: relative;
}

.arrow_icon {
    position: absolute;
    bottom: 30px;
    /* Adjust this value as needed */
    left: 50px;
    /* Adjust this value as needed */
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
    .arrow_icon {
        bottom: 30px;
        /* Position for mobile */
        left: 30px;
        /* Adjust this value as needed */
    }
}

.text-container {
    /* Optional: Adjust margin/padding as needed */
    margin-right: 30px;
    /* Ensure arrow doesn't overlap text */
}

.onova_same_faq .faq_accoridian .card-body {
    border: 2px solid #e0e9f4;
    font-size: 14px;
}

.onova_same_faq .faq_accoridian .card .show .card-body {
    border-top: 0;
}

.onova_same_faq .faq_content_box {
    margin-bottom: 40px;
}

.onova_faq_3 .faq_img_box .video_play {
    top: 50%;
}

.onova_faq_3 .faq_img_box {
    position: relative;
}

.video_play {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video_play .play_btn {
    display: inline-block;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
    background: rgb(5, 116, 255);
    transform: all .5s;
}

/*
	End faq.html css
*/

/*
	Start onova_team_2.html css
*/
.team_box_2 {
    margin-bottom: 30px;
}

.team_box_2 .team_info h3 {
    color: #0d2335;
}

.team_box_2 .team_info p {
    color: #0574ff;
}

/*
	End onova_team_2.html css
*/

/*
	Start shop.html css
*/
.shop_main_top {
    margin-bottom: 40px;
}

.shop_main_top .shop_main_sort {
    float: right;
}

.shop_main_top .shop_main_sort .top_select {
    display: inline-block;
    width: 217px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border: 1px solid rgb(234, 234, 234);
    padding: 0 30px;
}

.shop_page.section_padding {
    padding-top: 120px;
    padding-bottom: 74px;
}

.onova_shop_details.shop_details_1 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.nice-select.open .list {
    width: 100%;
}

.product_box {
    margin-bottom: 40px;
}

.product_box .product_img {
    margin-bottom: 30px;
    position: relative;
}

.product_box .product_img::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: rgba(26, 126, 251, 0.8);
    opacity: 0;
    transition: all linear 0.3s;
}

.product_box:hover .product_img::before {
    opacity: 1;
}

.product_box .product_img img {
    width: 100%;
}

.product_info h4 {
    font-size: 18px;
    color: #000;
}

.product_info h4 a {
    transition: all linear 0.3s;
}

.product_info h4 a:hover {
    color: #1a7efb;
}

.product_info h4 span {
    float: right;
    font-size: 16px;
    color: #0574ff;
}

.shop_sidebar .widget_sidebar_box {
    margin-bottom: 60px;
}

.shop_sidebar .widget_sidebar_box .sidebar_widget_title h5 {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.shop_sidebar .widget_sidebar_box .sidebar_widget_title h5:before {
    display: inline-block;
    content: '';
    width: 16px;
    height: 1px;
    margin-right: 10px;
    background: rgb(5, 116, 255);
}

.shop_sidebar .widget_sidebar_box ul {
    padding-left: 25px;
}

.categroies_widget ul li {
    line-height: 40px;
}

.widget_sidebar_box ul li a {
    color: #758ca0;
    font-size: 15px;
    text-transform: capitalize;
}

.categroies_widget ul.categroies_list li a:before {
    display: inline-block;
    content: '\f067';
    font-family: 'Font Awesome 5 Pro';
    margin-right: 5px;
}

.widget_sidebar_box h5.link {
    padding-left: 25px;
    margin-top: 15px;
}

.widget_sidebar_box ul.color_list li a {
    align-items: center;
    display: inline-flex;
}

.widget_sidebar_box ul.color_list li a:before {
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgb(0, 0, 0);
    margin-right: 5px;
    background: rgb(0, 0, 0);
}

.widget_sidebar_box ul.color_list li a.blue:before {
    background: #1e73be;
}

.widget_sidebar_box ul.color_list li a.brown:before {
    background: #9b5017;
}

.widget_sidebar_box ul.color_list li a.gray:before {
    background: #5b5b5b;
}

.widget_sidebar_box ul.color_list li a.orange:before {
    background: #f77e38;
}

.widget_sidebar_box ul.color_list li a.white:before {
    background: #fff;
    border: 1px solid rgb(238, 238, 238);
}

.widget_product .single_product {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.widget_product .single_product .product_info {
    margin-left: 20px;
}

.product_info h3 {
    font-size: 15px;
    margin-bottom: 10px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
    h3 {
        font-size: 20px;
        /* Change font size to 20px on mobile devices */
    }
}


.widget_sidebar_box ul.tags_list li {
    display: inline-block;
}

.widget_sidebar_box ul.tags_list li a {
    padding: 5px 20px;
    display: block;
    background: #0d2335;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.widget_sidebar_box ul.tags_list li a.btn_2 {
    padding: 5px 10px;
}

/*
	End shop.html css
*/

/*
	Start shop_details.html css
*/
.product_discription_details .product_img img {
    width: 100%;
}

.product_discription_details .product_details .product_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}

.product_discription_details .product_details .product_title .text_right {
    text-align: right;
}

.product_discription_details .product_details .product_color {
    padding: 20px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.product_discription_details .product_details .product_color ul li {
    display: inline-block;
}

.product_discription_details .product_details .product_color ul li span {
    font-size: 20px;
    color: #0d2335;
    font-family: 'Gilroy-Bold.ttf';
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 20px;
}

.product_discription_details .product_details .product_color ul li a {
    display: block;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: rgb(91, 91, 91);
}

.product_discription_details .product_details .product_text p {
    padding: 25px 0;
}

.product_discription_details .product_details .product_text ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.product_discription_details .product_details .product_text ul li a {
    color: #758ca0;
}

.product_discription_details .product_details .product_add {
    display: flex;
}

.product_discription_details .product_details .product_add input {
    background: rgb(13, 35, 53);
    width: 104px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border: none;
    color: #fff;
    margin-right: 10px;
}

.product_discription_details .product_details .product_add .onova_btn {
    border-radius: 0;
    padding: 0;
    width: 154px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin-right: 10px;
}

.product_discription_details .product_details .product_add .heart {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    color: #333333;
    text-align: center;
    border: 1px solid rgb(51, 51, 51);
    background-color: rgba(51, 51, 51, 0);
    transition: all .5s;
}

.product_discription_details .product_details .product_add .heart:hover,
.product_discription_details .product_details .product_add .heart:focus {
    background: rgb(13, 35, 53);
    color: #fff;
}

.product_discription_details {
    padding-bottom: 120px;
    border-bottom: 1px solid #e5e5e5;
}

.shop_details_1 .comment_area {
    padding-top: 80px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e5e5e5;
}

.shop_details_1 .comment_area .comment_single {
    display: flex;
    margin-bottom: 50px;
}

.shop_details_1 .comment_area .comment_single:nth-child(even) {
    padding-left: 110px;
}

.shop_details_1 .comment_area .comment_single .comment_img {
    width: 150px;
}

.shop_details_1 .comment_area .comment_single .comment_img img {
    width: 80px;
    height: 80px;
}

.shop_details_1 .comment_area .comment_single .comment_info {
    margin-left: 30px;
}

.shop_details_1 .comment_area .comment_single .comment_info p {
    font-size: 14px;
}

.shop_details_1 .review_from {
    margin-top: 45px;
}

.shop_details_1 .review_from .review_title .inner_title h5 {
    margin-bottom: 25px;
}

/*
	End shop_details.html css
*/

/*
	Start portfolio_1.html css
*/
.portfolio_button {
    margin-bottom: 40px;
}

.portfolio_btn {
    letter-spacing: 2px;
    font-size: 14px;
    color: #758ca0;
    border: none;
    background: transparent;
    margin-left: 30px;
    margin-right: 30px;
    text-transform: uppercase;
}

.portfolio_btn:hover,
.portfolio_btn:focus {
    color: #0574ff;
}

.portfolio_btn.btn_active {
    color: #0574ff;
}

.portfolio_box.portfolio_box_1 {
    position: relative;
    margin-bottom: 110px;
    transition: all linear 0.3s;
}

.portfolio_box.portfolio_box_1:hover {
    margin-top: -15px;
}

.portfolio_box_1 .portfolio_img {
    position: relative;
    z-index: -1;
}

.portfolio_box_1 .portfolio_info {
    display: flex;
    align-items: center;
    padding: 40px;
    background: #fff;
    margin: 0 auto;
    width: 270px;
    height: 113px;
    margin-top: -50px;
    z-index: 1;
    border: 1px solid #eaeaea;
}

.onova_portfolio.onova_portfolio_2 {
    padding-bottom: 15px;
}

.portfolio_box_1 .portfolio_info .portfolio_text {
    float: left;
}

.portfolio_box_1 .portfolio_info .portfolio_text span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: #0574ff;
    letter-spacing: 2px;
}

.portfolio_box_1 .portfolio_info .portfolio_text h3 {
    font-size: 22px;
}

.portfolio_box_1 .portfolio_info .portfolio_icon {
    margin-left: 50px;
}

.portfolio_box_1 .portfolio_info .portfolio_icon i {
    font-size: 30px;
    color: #cbdae6;
}

.load_button {
    margin-top: 15px;
}

.load_button .load_btn {
    display: inline-block;
    padding: 25px 50px;
    background: #fff;
    border: 1px solid rgb(198, 215, 229);
    font-size: 14px;
    color: #758ca0;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.load_button .load_btn:after {
    content: '\f067';
    margin-left: 15px;
    font-family: 'Font Awesome 5 Pro';
}

/*
	End portfolio_1.html css
*/

/*
	Start portfolio_2.html css
*/
.portfolio_container {
    max-width: 1570px;
    margin: 0 auto;
}

.portfolio_box_2 .portfolio_info {}

@media screen only (min-width: 1170px) {
    .portfolio_container {
        max-width: 100%;
    }

    .portfolio_box_2 .portfolio_info {
        padding-left: 25px;
        padding-right: 25px;
    }
}

/*
	End portfolio_2.html css
*/

/*
	Start portfolio_3.html css
*/
.portfolio_box_3 {
    padding: 40px 35px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(198, 215, 229);
    margin-bottom: 30px;
}

.portfolio_box_3 .portfolio_tag a.tag {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: #0574ff;
    letter-spacing: 2px;
}

.portfolio_box_3 .portfolio_img {
    margin-top: 40px;
    padding: 20px 27px;
    background: rgb(255, 247, 247);
}

.portfolio_box_3 .portfolio_info {
    margin-top: 40px;
}

.portfolio_box_3 .portfolio_info p {
    font-size: 14px;
}

.portfolio_box_3 .portfolio_info a.btn_link {
    font-size: 14px;
    text-transform: capitalize;
    color: #758ca0;
}

/*
	End portfolio_3.html css
*/

/*
	Start case_details.html css
*/
.case_top_detail {
    margin-bottom: 60px;
}

.case_top_detail .case_top_box h3 {
    margin-bottom: 15px;
}

.case_top_detail .case_top_box p {
    font-size: 16px;
}

.case_top_img .onova_img {
    position: relative;
    z-index: -1;
}

.case_top_content {
    padding: 0 40px;
    margin-top: -80px;
    z-index: 1;
}

.onova_img.about_img_2>img {
    width: 100%;
}

.onova_img>img {
    width: 100%;
    margin-top: 0;
}

.team_box .onova_img {
    position: relative;
}

.team_box .onova_img::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(13, 35, 53, 0.8);
    transition: all linear 0.3s;
    opacity: 0;
}

.team_box:hover .onova_img::after {
    opacity: 1;
}

.case_inner_content {
    padding: 70px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 8px 0px rgba(206, 216, 230, 0.3);
}

.case_inner_content h2 {
    margin-bottom: 15px;
}

.case_top_main_content p {
    margin-bottom: 50px;
    margin-top: 40px;
}

.case_goals {
    margin-top: 55px;
    margin-bottom: 60px;
}

.case_goals .goal_bar,
.case_goals .case_goal_img {
    position: relative;
    margin-top: 50px;
}

.case_goal_img {
    position: relative;
}

.case_goal_img .video_button a.play_btn:after,
.case_goal_img .video_button a.play_btn:before {
    border-color: #fff;
}

.case_sumary {
    margin-bottom: 80px;
}

/*
	End case_details.html css
*/

/*
	Start blog_grid.html css
*/
.blog_box_3,
.blog_box_4 {
    margin-bottom: 30px;
}

.onova_single_blog.single_blog_main {
    padding-top: 85px;
    padding-bottom: 85px;
}

/*
	End blog_grid.html css
*/


/*
	Start blog_list.html css
*/
.blog_img {
    position: relative;
    overflow: hidden;
}

.blog_img img {
    width: 100%;
    transform: scale(1);
    transition: all linear 0.3s;
}

.blog_box:hover .blog_img img {
    transform: scale(1.1);
}



.blog_box_5 {
    margin-bottom: 50px;
}

.blog_box_5 .blog_info {
    padding: 50px;
    border: 1px solid rgb(208, 231, 250);
}

.blog_box_5 .blog_info h3 {
    font-size: 32px;
    line-height: 50px;
    margin-bottom: 12px;

}

.blog_box_5 .blog_info .post_meta {
    padding: 0;
    border: none;
    margin-bottom: 10px;
}

.blog_box_5 .blog_info .post_meta ul {
    display: flex;
    text-align: left;
    justify-content: flex-start;
}

.blog_box_5 .blog_info .post_meta ul li {
    margin-left: 0;
    margin-right: 25px;
}

.bl_main_info p {
    padding: 25px 0;
}

.blog_play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blog_play .play_btn {
    display: block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    background: rgb(26, 126, 251);
    border-radius: 50%;
}

.blog_box_5 .blog_info_2 .blog_text h3 {
    font-size: 30px;
    line-height: 40px;
}

.blog_icon {
    float: left;
    margin-top: 10px;
}

.blog_text {
    margin-left: 80px;
}

.onova_paginaition ul li {
    display: inline-block;
    margin-right: 15px;
}

.onova_paginaition ul li a {
    display: inline-block;
    width: 54px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    font-size: 14px;
    color: #758ca0;
    background: transparent;
    border-radius: 6px;
    border: 1px solid rgb(208, 231, 250);
    transition: all .5s;
}

.onova_paginaition ul li a:hover,
.onova_paginaition ul li a:focus {
    background: rgb(26, 126, 251);
    color: #fff;
}

/*sidebar widget*/

.blog_sidebar .widget_sidebar_box {
    padding: 40px 25px;
    border: 1px solid rgb(208, 231, 250);
    border-radius: 6px;
    margin-bottom: 60px;
}

.blog_sidebar .sidebar_widget_title {
    position: relative;
    margin-bottom: 30px;
}

.blog_sidebar .sidebar_widget_title h3 {
    font-size: 28px;
    color: #170e2a;
    margin-left: 95px;
    font-weight: bold;
}

.blog_sidebar .sidebar_widget_title h3:before {
    content: '';
    background: url(../images/line.png);
    display: inline-block;
    position: absolute;
    width: 60px;
    height: 4px;
    background-repeat: no-repeat;
    top: 15px;
    left: 10px;
}

.blog_sidebar .search_widget form {
    position: relative;
}

.blog_sidebar .search_widget input {
    position: relative;
    width: 100%;
    height: 60px;
    font-size: 12px;
    color: #6870a0;
    border: 1px solid rgb(208, 231, 250);
    padding: 0px 0px 0px 28px;
    border-radius: 6px;
}

.blog_sidebar .search_widget input::placeholder {
    color: #6870a0;
}

.blog_sidebar .search_widget button.search_btn {
    position: absolute;
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    color: #fff;
    top: 0;
    right: 0;
    border: none;
    background: rgb(26, 126, 251);
    border-radius: 6px;
}

.blog_sidebar .instagram_widget .ins_pa {
    padding-right: 7px;
    padding-left: 7px;
}

.blog_sidebar .instagram_widget .insta_img {
    margin-bottom: 15px;
}

.blog_sidebar .instagram_widget .insta_img img {
    width: 100%;
    border-radius: 6px;
}

.blog_sidebar .social_widget_link li {
    display: inline-block;
    margin-right: 10px;
}

.blog_sidebar .social_widget .social_widget_link li a {
    display: block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    border: 1px solid rgb(227, 227, 227);
    border-radius: 6px;
    font-size: 16px;
    color: #c3c3c3;
    transition: all .5s;
}

.blog_sidebar .social_widget .social_widget_link li a:hover,
.blog_sidebar .social_widget .social_widget_link li a:focus {
    background: #1a7efb;
    color: #fff;
}

.blog_sidebar .categroies_widget .categories_list li {
    line-height: 24px;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 16px;
}

.blog_sidebar .categroies_widget .categories_list li:last-child {
    margin-bottom: 0;
}

.blog_sidebar .categroies_widget .categories_list li:last-child a {
    padding-bottom: 0;
}

.blog_sidebar .categroies_widget .categories_list li a {
    display: block;
    padding-bottom: 16px;
    color: #6870a0;
}

.blog_sidebar .categroies_widget .categories_list li:last-child {
    border-bottom: none;
}

.blog_sidebar .categroies_widget .categories_list li a span {
    float: right;
}

.blog_sidebar .feeds_widget .feeds_single {
    text-align: left;
    overflow: hidden;
    margin-bottom: 20px;
}

.blog_sidebar .feeds_widget .feeds_single .feeds_img {
    width: 80px;
    float: left;
    margin-right: 15px;
}

.blog_sidebar .feeds_widget .feeds_single .feeds_img img {
    width: 100%;
    border-radius: 6px;
}

.blog_sidebar .feeds_widget .feeds_single .feeds_info {
    overflow: hidden;
}

.blog_sidebar .feeds_widget .feeds_single .feeds_info h6 a {
    display: block;
    color: #170e2a;
    margin-bottom: 5px;
}

.blog_sidebar .feeds_widget .feeds_single .feeds_info p {
    color: #6870a0;
    font-size: 14px;
}

.blog_sidebar .feeds_widget .feeds_single .feeds_info p i {
    margin-right: 10px;
    color: #1a7efb;
}

.blog_sidebar .tags_widget .tags_list {
    text-align: left;
}

.blog_sidebar .tags_widget .tags_list li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.blog_sidebar .tags_widget .tags_list li a {
    display: block;
    border: 1px solid rgb(234, 234, 234);
    font-size: 14px;
    border-radius: 6px;
    color: #758ca0;
    background: rgba(0, 0, 0, 0);
    margin-bottom: 5px;
}

.blog_sidebar .tags_widget .tags_list li a.tag_1 {
    padding: 15px 25px;
}

.blog_sidebar .tags_widget .tags_list li a.tag_2 {
    padding: 15px 25px;
}

.blog_sidebar .tags_widget .tags_list li a.tag_3 {
    padding: 15px 20px;
}

.blog_sidebar .tags_widget .tags_list li a.tag_5 {
    padding: 15px 15px;
}

.blog_sidebar .add_widget {
    position: relative;
    text-align: center;
}

.blog_sidebar .add_widget .add_widget_img {
    background: url(../images/add_img.jpg) no-repeat;
    background-size: cover;
    padding: 50px 38px 50px 48px;
    position: relative;
    z-index: 1;
}

.blog_sidebar .add_widget .add_widget_img .add_widget_text span {
    display: block;
    font-size: 14px;
    letter-spacing: 2px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 126px;
}

.blog_sidebar .add_widget .add_widget_img .add_widget_text h2 {
    font-size: 37px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 85px;
}

.blog_sidebar .add_widget .add_widget_img .add_widget_text .onova_btn {
    background: #0e64d0;
}

.blog_sidebar .add_widget .add_widget_img:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: '';
    opacity: .9;
    z-index: -1;
    background: rgb(5, 116, 255);
    border-radius: 6px;
}

.onova_contact_form {
    margin-top: 0;
    margin-bottom: 110px;
    padding-top: 110px;
    padding-bottom: 120px;
}

.onova_contact_information {
    padding-bottom: 120px;
}











/*
	End blog_list.html css
*/

/*
	Start single_blog.html css
*/
.single_blog_main_content {
    padding: 50px 60px;
    border: 1px solid rgb(208, 231, 250);
}

.single_blog_main_content .post_link {
    margin-bottom: 6px;
}

.single_blog_main_content .post_link ul li {
    display: inline-block;
    margin-right: 30px;
}

.single_blog_main_content .post_link ul li a {
    color: #6870a0;
    font-size: 14px;
}

.single_blog_main_content .post_link ul li i {
    margin-right: 6px;
}

.content_text_area h2 {
    font-size: 30px;
    line-height: 46px;
    margin-bottom: 30px;
}

.content_text_area p {
    margin-bottom: 45px;
}

.content_text_area .blockquote {
    padding: 30px 45px 30px 60px;
    margin-bottom: 45px;
    border: 1px solid rgb(208, 231, 250);
    display: flex;
    align-items: center;
}

.content_text_area .blockquote .blockquote_icon {
    float: left;
}

.content_text_area .blockquote .blockqoute_text {
    margin-left: 25px;
}

.content_text_area .blockquote .blockqoute_text p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    padding-bottom: 15px;
}

.content_text_area .blockquote .blockqoute_text h6 {
    font-size: 14px;
}

.content_share_area {
    clear: both;
}

.tags_area h4,
.social_area h4 {
    font-size: 18px;
    margin-bottom: 20px;
}

.tags_area ul li {
    margin-bottom: 0;
}

.tags_area ul li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
}

.tags_area ul li a {
    display: block;
    border: 1px solid rgb(234, 234, 234);
    font-size: 14px;
    border-radius: 6px;
    color: #758ca0;
    background: rgba(0, 0, 0, 0);
    margin-bottom: 5px;
    padding: 10px 15px;
}

.tags_area a {
    display: inline-block;
    border: 1px solid rgb(234, 234, 234);
    font-size: 14px;
    border-radius: 6px;
    color: #758ca0;
    background: rgba(0, 0, 0, 0);
    margin-bottom: 5px;
    padding: 10px 15px;
}

.social_area {
    text-align: right;
}

.social_area ul.social_link {
    float: right;
    margin-bottom: 0;
}

.social_area ul.social_link li {
    margin-left: 30px;
    margin-right: 0;
}

.prev_next_area {
    padding: 45px 0;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}

.prev_next_area .post_content h4 {
    font-size: 22px;
}

.prev_next_area .post_content a {
    color: #b2c4d3;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.comment_title h4,
.post_title h4 {
    font-size: 26px;
    font-weight: bold;
    color: #170e2a;
    margin-bottom: 35px;
}

.onova_about.onova_about_3 {
    padding-top: 116px;
    padding-bottom: 120px;
}

.content_share_area,
.about_admin,
.comment_area {
    margin-bottom: 50px;
}

.about_admin {
    padding: 53px 55px;
    position: relative;
    border: 1px solid rgb(208, 231, 250);
}

.admin_img {
    position: absolute;
    top: -65%;
    left: 50%;
    transform: translate(-50%, 20%);
}

.admin_img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

ul.social_link {
    margin-bottom: 15px;
}

ul.social_link li {
    display: inline-block;
    margin-right: 10px;
}

ul.social_link li a {
    font-size: 12px;
    color: #758ca0;
}

ul.social_link li a:hover,
ul.social_link li a:focus {
    color: #1a7efb;
}

.admin_bio {
    margin-top: 40px;
}

.admin_bio h4 {
    font-size: 26px;
    font-weight: bold;
    color: #170e2a;
    margin-bottom: 10px;
}

.admin_bio p {
    font-size: 14px;
    line-height: 24px;
}

.comment_list_area {
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
}

.comment_list_area .single_comment {
    margin-bottom: 50px;
}

.comment_list_area .single_comment .comment_img {
    float: left;
}

.comment_list_area .single_comment .comment_img img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.comment_list_area .single_comment .comment_text {
    margin-left: 130px;
}

.comment_list_area .single_comment .comment_text h5 {
    color: #170e2a;
    font-weight: bold;
    margin-bottom: 10px;
}

.comment_list_area .single_comment .comment_text h5 i {
    color: #cacfef;
    font-size: 12px;
}

.comment_list_area .single_comment .comment_text h6 {
    color: #1a7efb;
    letter-spacing: 2px;
    font-weight: 500;
    margin-bottom: 20px;
}

.comment_list_area .single_comment .comment_text p {
    font-size: 14px;
    line-height: 24px;
}

.comment_list_area .single_comment .comment_text h5 i {
    margin-left: 25px;
}

ol {
    list-style-type: decimal;
    /* Ensures ordered lists have decimal numbers */
}

ol>li {
    list-style-type: disc;
    /* Ensures list items inside ordered lists have bullet points */
    margin-left: 20px;
    /* Adds indentation to list items for better readability */
}

.comment_list_area .single_comment .comment_text h5 span {
    float: right;
    font-size: 14px;
    text-transform: capitalize;
}

.comment_list_area .single_comment .comment_text h5 span a {
    color: #7886a0;
}

.comment_list_area .single_comment .comment_text h5 span a i {
    margin-right: 4px;
    color: #7886a0;
}

.post_form {
    padding: 50px;
    border: 1px solid rgb(208, 231, 250);
}

.post_form .form_group i {
    top: 25px;
    color: #1a7efb;
}

.post_form .post_button button {
    border-radius: 0;
    padding: 0 75px;
    line-height: 60px;
}

.post_form .post_button label {
    color: #758ca0;
    font-size: 14px;
    padding-left: 5px;
}

textarea::placeholder {
    color: #758ca0;
    font-size: 14px;
    opacity: 1;
    text-transform: uppercase;
}

input::placeholder {
    color: #758ca0;
    font-size: 14px;
    opacity: 1;
    text-transform: uppercase;
}

input:focus {
    border-color: #1a7efb;
}

textarea:focus {
    border-color: #1a7efb;
}

/*
	End single_blog.html css
*/


/*
	Start contact.html css
*/
.onova_contact_form {
    position: relative;
}

.contact_form_3 .form_control {
    background: #fff;
    border-color: rgb(207, 230, 241);
}

.contact_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.contact_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.contact_form_3,
.contact_map {
    box-sizing: border-box;
    padding: 15px;
}

.contact_form_3 {
    flex: 1 1 50%;
}

.contact_map {
    flex: 1 1 50%;
}

.contact_map iframe {
    width: 100%;
    height: 500px;
    border: none;
}

@media (max-width: 768px) {

    .contact_form_3,
    .contact_map {
        flex: 1 1 100%;
    }

    .contact_map iframe {
        height: 300px;
    }
}


.contact_information_box {
    padding: 15px;
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 8px 0px rgba(206, 216, 230, 0.3);
}

.contact_information_box:hover .information_box {
    border-color: rgb(5, 116, 255);
}

.contact_information_box:hover .info_button {
    border-color: rgb(5, 116, 255);
}

.information_box {
    text-align: center;
    background: rgb(255, 255, 255);
    border: 2px solid rgb(244, 248, 255);
    transition: all .5s;
}

.information_icon i {
    font-size: 50px;
    color: #0574ff;
    margin-top: 57px;
    margin-bottom: 40px;
}

.information_info {
    margin-bottom: 60px;
}

.information_info h3 {
    font-size: 30px;
    margin-bottom: 30px;
}

.information_info p {
    line-height: 30px;
}

.info_button {
    border-top: 2px solid rgb(244, 248, 255);
    padding: 20px 0;
    text-align: center;
    transition: all .5s;
}

.info_button a.info_btn {
    font-size: 16px;
    color: #a4b2bf;
    text-transform: capitalize;
}

.call_title_3 span {
    color: #7d94a6;
}

.call_title_3 h2 {
    font-size: 58px;
    line-height: 70px;
    color: #fff;
}

/*
	End contact.html css
*/

/*
	Start 404.html css
*/
.content_404 {
    position: relative;
}

.content_404 img.text_png {
    z-index: -1;
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
}

.content_404 a.error_btn {
    margin-bottom: 100px;
    padding: 16px 56px;
}

.content_404 span {
    font-size: 16px;
    color: #0574ff;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.content_404 h2 {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 40px;
}

/* End 404.html css */





/*	 scroll_up  css*/

#scroll_up {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background: #1A7EFB;
    text-align: center;
    line-height: 40px;
    color: #fff;
    cursor: pointer;
    display: none;
    z-index: 9;
}




/*	 scroll_up  css*/


/*===========================
    SEARCH css 
===========================*/
.search-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999999;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.6s ease-out 0s;
    -moz-transition: all 0.6s ease-out 0s;
    -ms-transition: all 0.6s ease-out 0s;
    -o-transition: all 0.6s ease-out 0s;
    transition: all 0.6s ease-out 0s;
    opacity: 0;
}

.search-box .search-header .search-title {
    font-size: 24px;
    font-weight: 500;
    color: #222;
}

.search-box .search-header .search-close button {
    background: none;
    border: 0;
    font-size: 24px;
    font-weight: 500;
    color: #222;
    padding-right: 35px;
    position: relative;
    padding-top: 6px;
}

.search-box .search-header .search-close button span {
    width: 21px;
    height: 2px;
    background-color: #222;
    display: block;
    position: absolute;
    right: 0;
}

.search-box .search-header .search-close button span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.search-box .search-header .search-close button span:nth-child(2) {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 18px;
}

.search-box .search-body {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.search-box .search-body .search-form {
    position: relative;
}

.search-box .search-body .search-form input {
    width: 100%;
    border: 0;
    border-bottom: 2px solid #ecedff;
    font-size: 30px;
    color: #000;
    font-size: 40px;
    line-height: 100px;
    font-weight: 700;
}

.search-box .search-body .search-form input::placeholder {
    color: #000;
    opacity: 1;
    font-size: 40px;
    font-weight: 700;
}

.search-box .search-body .search-form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    font-size: 36px;
    color: #707070;
    background: none;
    border: 0;
}

.search-box .search-footer {
    padding-bottom: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.search-box .search-footer .search-footer-content h4 {
    color: #707070;
    font-size: 24px;
}

.search-box .search-footer .search-footer-content p {
    color: #222;
    font-size: 16px;
}

.search-box.open {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.search-results {
    margin-top: 10px;
}

.search-results ul li a {
    display: inline-block;
    padding: 10px 0;
    border-bottom: 1px solid rgb(5, 116, 255);
    color: #222;

}

.icon_info h2 {
    text-transform: inherit;
}